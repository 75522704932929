<template>
  <Modal title="绑定设备" :value="value" @input="(val) => $emit('input', val)">
    <Table
      @on-select="select"
      @on-select-all="selectAll"
      @on-select-cancel="cancel"
      @on-select-all-cancel="cancelAll"
      :columns="columns"
      :loading="tableLoading"
      :data="tableData"
      size="small"
      border
    ></Table>
    <div style="text-align: right">
      <Page
        size="small"
        @on-change="pageChange"
        :current="page.current"
        :page-size="page.pageSize"
        :total="page.total"
      ></Page>
    </div>
    <template #footer>
      <div style="text-align: center">
        <Button @click="save" type="primary">确定</Button>
        <Button @click="() => $emit('input', false)">取消</Button>
      </div>
    </template>
  </Modal>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      false: false,
    },
    defaultSelection: {
      type: Array,
      default() {
        return [];
      },
    },
    typeGuid: String,
    farmId: [String, Number],
    sceneId: [String, Number],
  },
  data() {
    return {
      tableLoading: false,
      tableData: [],
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      selectedMap: new Map(),
    };
  },
  computed: {
    columns() {
      return [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "设备名称",
          align: "center",
          key: "groupName",
          render: (c, { row }) => {
            return (
              <span>
                {row.groupName && row.name
                  ? row.groupName + " / " + row.name
                  : row.groupName || row.name}
              </span>
            );
          },
        },
      ];
    },

    rowKey() {
      return this.typeGuid === "de282d14-9fd0-407b-9c67-0e67ae3c0af0"
        ? "id"
        : "guid";
    },
  },
  methods: {
    //   表格单选
    select(selections, row) {
      this.selectedMap.set(row[this.rowKey], { ...row });
    },
    //   表格全选
    selectAll(selections) {
      selections.forEach((item) => {
        this.selectedMap.set(item[this.rowKey], { ...item });
      });
    },
    //  表格单取消
    cancel(selections, row) {
      this.selectedMap.has(row[this.rowKey]) &&
        this.selectedMap.delete(row[this.rowKey]);
    },
    // 表格全取消
    cancelAll() {
      this.tableData.forEach((item) => {
        this.selectedMap.has(item[this.rowKey]) &&
          this.selectedMap.delete(item[this.rowKey]);
      });
    },

    //   分页改变
    pageChange(pageNo) {
      this.page.current = pageNo;
      this.getTableData();
    },
    //   获取表格数据
    getTableData() {
      let url;
      let params;
      switch (this.typeGuid) {
        case "c9207b50-e61e-45be-a378-4fb61d0bcefd":
          url = this.$api.BUSSDEVICEVIDEO.LIST;
          params = {
            pageNo: this.page.current,
            pageSize: this.page.pageSize,
          };
          this.farmId && (params.farmId = this.farmId);
          this.sceneId && (params.sceneId = this.sceneId);
          break;
        default:
          url = this.$api.BUSSDEVICESENSOR.LIST;
          params = {
            pageNo: this.page.current,
            pageSize: this.page.pageSize,
          };
          this.farmId && (params.farmId = this.farmId);
          this.sceneId && (params.sceneId = this.sceneId);
          break;
      }
      this.tableLoading = true;
      this.$post(url, params)
        .then((res) => {
          res.list.forEach((item) => {
            if (this.selectedMap.has(item[this.rowKey])) {
              item._checked = true;
            }
          });
          let dataInfo = {};
          res.list.forEach((item) => {
            let { groupName } = item;
            if (!dataInfo[groupName]) {
              dataInfo[groupName] = {
                groupName,
                child: [],
              };
            }
            dataInfo[groupName].child.push(item);
          });
          let arr = Object.values(dataInfo).map((item) => item.child);
          let data = [];
          for (let i = 0; i < arr.length; i++) {
            data = data.concat(arr[i]);
          }
          this.tableData = data;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 清空数据
    clear() {
      this.tableData = [];
      this.tableLoading = false;
      this.selectedMap.clear();
    },
    // 保存数据
    save() {
      let values = [...this.selectedMap.values()];
      this.$emit("save", values);
    },
  },
  mounted() {
    if (this.value) {
      this.defaultSelection.forEach((item) => {
        this.selectedMap.set(item[this.rowKey], item);
      });
      this.getTableData();
    }
  },
  watch: {
    value(value) {
      if (value) {
        this.defaultSelection.forEach((item) => {
          this.selectedMap.set(item[this.rowKey], item);
        });
        this.getTableData();
      } else this.clear();
    },
  },
};
</script>

<style>
</style>